import axios from "axios";
import React, { useEffect, useState } from "react";

const Welcome = () => {
  const [loading, setLoading] = useState(true);
  const [setting, setSetting] = useState({});
  useEffect(() => {
    axios.get("https://admin.riaschool.edu.np/api/v1/setting").then((res) => {
      if (res.status === 200) {
        setSetting(res.data.data.setting);
      }
      setLoading(false);
    });
  }, []);

  console.log("Setting", setting);
  if (loading || !setting) {
    return "";
  } else {
    return (
      <div class="container">
        <div className="grules my-3">
          <h2 style={{ textAlign: "center" }}>Welcome To RIA School</h2>
          <div className="rule-info">
            <span
              dangerouslySetInnerHTML={{ __html: setting.org_description }}
            ></span>
          </div>
        </div>
      </div>
    );
  }
};

export default Welcome;
