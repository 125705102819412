import axios from "axios";
import React, { useState } from "react";

const AdmissionForm = () => {
  const [values, setValues] = useState({
    name: "",
    contact: "",
    email: "",
    message: "",
  });

  const [flashmsg, setFlashmsg] = useState({});
  const [showFlash, setShowFlash] = useState(false);

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let mail = { ...values };
    axios
      .post("https://admin.riaschool.edu.np/api/v1/sendmail", mail)
      .then((res) => {
        if (res.status === 200) {
          setFlashmsg(res.data.message);
          setShowFlash(true);
          setValues({
            name: "",
            contact: "",
            email: "",
            message: "",
          });
        }
      })
      .catch((err) => {
        console.log("Err", err);
      });
    setTimeout(() => {
      setShowFlash(false);
    }, 5000);
  };
  return (
    <div>
      <button
        type="button"
        className="btn btn-sm btn-danger"
        data-bs-toggle="modal"
        data-bs-target="#registerForm"
        data-bs-whatever="@mdo"
      >
        Apply For Admission
      </button>
      <div
        className="modal fade"
        id="registerForm"
        tabIndex="-1"
        aria-labelledby="registerFormLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="registerFormLabel">
                Inquiry Message
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {!showFlash || showFlash === false ? (
                ""
              ) : (
                <div className="alert alert-success" role="alert">
                  {flashmsg}
                </div>
              )}
              <form
                onSubmit={handleSubmit}
                id="contactForm"
                data-sb-form-api-token="API_TOKEN"
              >
                {/* <!-- Name input --> */}
                <div className="mb-3">
                  <input
                    className="form-control"
                    id="name"
                    name="name"
                    type="text"
                    value={values.name || ""}
                    placeholder="Name"
                    data-sb-validations="required"
                    onChange={handleChange}
                  />
                  <div
                    className="invalid-feedback"
                    data-sb-feedback="name:required"
                  >
                    Name is required.
                  </div>
                </div>

                {/* Contact Number */}
                <div className="mb-3">
                  <input
                    className="form-control"
                    id="number"
                    name="contact"
                    type="text"
                    value={values.contact || ""}
                    placeholder="Number"
                    data-sb-validations="required"
                    onChange={handleChange}
                  />
                  <div
                    className="invalid-feedback"
                    data-sb-feedback="name:required"
                  >
                    Name is required.
                  </div>
                </div>

                {/* <!-- Email address input --> */}
                <div className="mb-3">
                  <input
                    className="form-control"
                    id="emailAddress"
                    name="email"
                    value={values.email || ""}
                    type="email"
                    placeholder="Email Address"
                    data-sb-validations="required, email"
                    onChange={handleChange}
                  />
                  <div
                    className="invalid-feedback"
                    data-sb-feedback="emailAddress:required"
                  >
                    Email Address is required.
                  </div>
                  <div
                    className="invalid-feedback"
                    data-sb-feedback="emailAddress:email"
                  >
                    Email Address Email is not valid.
                  </div>
                </div>

                {/* <!-- Message input --> */}
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    type="text"
                    value={values.message || ""}
                    placeholder="Message"
                    style={{ height: "10rem" }}
                    data-sb-validations="required"
                    onChange={handleChange}
                  ></textarea>
                  <div
                    className="invalid-feedback"
                    data-sb-feedback="message:required"
                  >
                    Message is required.
                  </div>
                </div>

                {/* <!-- Form submissions success message --> */}
                <div className="d-none" id="submitSuccessMessage">
                  <div className="text-center mb-3">
                    Form submission successful!
                  </div>
                </div>

                {/* <!-- Form submissions error message --> */}
                <div className="d-none" id="submitErrorMessage">
                  <div className="text-center text-danger mb-3">
                    Error sending message!
                  </div>
                </div>
              </form>
            </div>
            {/* <!-- Form submit button --> */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                onClick={(e) => handleSubmit(e)}
                className="btn btn-primary"
              >
                Send message
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdmissionForm;
