import React from "react";
import AcadMembers from "./AcadMembers";
import MissionVision from "./MissionVision";
import Rules from "./Rules";
import Uniform from "./Uniform";
import Welcome from "./Welcome";

const About = () => {
  return (
    <div>
      {/* <h1 className="text-center">About Us</h1> */}
      <Welcome />
      <MissionVision />
      <AcadMembers />
      <Rules />
      <Uniform />
    </div>
  );
};

export default About;
