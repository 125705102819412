import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Footer() {
  const [loading, setLoading] = useState(true);
  const [setting, setSetting] = useState({});

  useEffect(() => {
    axios.get("https://admin.riaschool.edu.np/api/v1/setting").then((res) => {
      if (res.status === 200) {
        setSetting(res.data.data.setting);
      }
    });
  }, []);

  let shortDesc;
  if (!setting.org_description) {
    return "";
  } else {
    shortDesc = (
      <span
        dangerouslySetInnerHTML={{
          __html: setting.org_description.substring(0, 255),
        }}
      ></span>
    );
  }

  return (
    <div>
      {/* <!-- Footer --> */}
      <footer className="text-center text-lg-start bg-danger text-white">
        {/* <!-- Section: Social media --> */}
        <section className="d-flex bg-primary text-white justify-content-center justify-content-lg-between p-4 border-bottom">
          {/* <!-- Left --> */}
          <div className="me-5 d-none d-lg-block">
            <span>Get connected with us on social networks:</span>
          </div>
          {/* <!-- Left --> */}

          {/* <!-- Right Social Links --> */}
          <div>
            <a href={setting.facebookurl} className="me-4 text-reset">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href={setting.twitterurl} className="me-4 text-reset">
              <i className="fab fa-twitter"></i>
            </a>
            <a href={setting.instagramurl} className="me-4 text-reset">
              <i className="fab fa-instagram"></i>
            </a>
            <a href={setting.linkedinurl} className="me-4 text-reset">
              <i className="fab fa-linkedin"></i>
            </a>
            <a href={setting.youtubeurl} className="me-4 text-reset">
              <i className="fab fa-youtube"></i>
            </a>
          </div>
          {/* <!-- Right --> */}
        </section>
        {/* <!-- Section: Social media --> */}

        {/* <!-- Section: Links  --> */}
        <section className="">
          <div className="container text-center text-md-start mt-5">
            {/* <!-- Grid row --> */}
            <div className="row mt-3">
              {/* <!-- Grid column --> */}
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                {/* <!-- Content --> */}
                <h6 className="text-uppercase fw-bold mb-4">
                  <i className="fas fa-gem me-1"></i>Reliance Int'l Academy
                </h6>
                {shortDesc}
                <Link
                  to={`/about`}
                  className="btn btn-dark btn-sm"
                >{`Read more >>`}</Link>
              </div>
              {/* <!-- Grid column --> */}

              {/* <!-- Grid column --> */}
              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
                {/* <!-- Links --> */}
                <h6 className="text-uppercase fw-bold mb-4">
                  Reliance Edu Network
                </h6>
                <p>
                  <a href="http://www.rianepal.edu.np/" className="text-reset">
                    Reliance International Academy (+2)
                  </a>
                </p>

                <p>
                  <a
                    href="http://www.riacollege.edu.np/"
                    className="text-reset"
                  >
                    Reliance College (BBS, BCA, BA, MBS)
                  </a>
                </p>

                <p>
                  <a href="http://www.rps.edu.np/" className="text-reset">
                    Reliance Public School
                  </a>
                </p>

                <p>
                  <a href="http://reliancecoed.edu.np/" className="text-reset">
                    Reliance Co&ndash;Ed. School
                  </a>
                </p>

                <p>
                  <a href="" className="text-reset">
                    Reliance Kids Home
                  </a>
                </p>
              </div>
              {/* <!-- Grid column --> */}

              {/* <!-- Grid column --> */}
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                {/* <!-- Links --> */}
                <h6 className="text-uppercase fw-bold mb-4">Quick Links</h6>
                <p>
                  <a href="/about" className="text-reset">
                    About
                  </a>
                </p>
                <p>
                  <a href="/facilities" className="text-reset">
                    Facilities
                  </a>
                </p>
                <p>
                  <a href="/awards" className="text-reset">
                    Activities & Awards
                  </a>
                </p>
                <p>
                  <a href="/cca" className="text-reset">
                    CCA & ECA
                  </a>
                </p>
              </div>
              {/* <!-- Grid column --> */}

              {/* <!-- Grid column --> */}
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                {/* <!-- Links --> */}
                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                <p>
                  <i className="fas fa-home me-3"></i> {setting.address || ""}
                </p>
                <p>
                  <i className="fas fa-envelope me-3"></i>
                  {setting.siteemail}
                </p>
                <p>
                  <i className="fas fa-phone me-3"></i> {setting.phone}
                </p>
              </div>
              {/* <!-- Grid column --> */}
            </div>
            {/* <!-- Grid row --> */}
          </div>
        </section>
        {/* <!-- Section: Links  --> */}

        {/* <!-- Copyright --> */}
        <div
          className="text-center p-4 bg-info"
          // style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
        >
          © {new Date().getFullYear()} Copyright:
          <a className="text-reset fw-bold" href="https://riaschool.edu.np">
            Reliance Academic School
          </a>
        </div>
        {/* <!-- Copyright --> */}
      </footer>
      {/* <!-- Footer --> */}
    </div>
  );
}

export default Footer;
