import React from "react";

const Coordinator = ({ profiles, image }) => {
  let member;
  let preprimary;
  let primary;
  let secondary;
  if (!profiles) {
    return (
      <div className="text-center align-item-center my-3">
        <div
          className="spinner-border text-success"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    member = profiles.map((profile, idx) => {
      if (profile.designation === "Pre-primary Coordinator") {
        return (preprimary = (
          <div className="card">
            <div className="proImage">
              <img
                src={`${
                  profile
                    ? `${image.main_image_url}/${profile.image}`
                    : "./images/profile-image.png"
                }`}
                width="100%"
                alt={profile.name}
              />
            </div>
            <div className="profile-info text-center">
              <h6>{profile.name}</h6>
              <span>{profile.qualification}</span>
              <br />
              <span>{profile.designation}</span>
            </div>
            <div className="profile-divider">
              <div className="social-link">
                {profile.facebookurl ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={profile.facebookurl}
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                ) : (
                  ""
                )}
                {profile.instagramurl ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${profile.instagramurl}`}
                  >
                    <i className="fa fa-instagram mx-3"></i>
                  </a>
                ) : (
                  ""
                )}
                {profile.twitterurl ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={profile.twitterurl}
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ));
      }
      if (profile.designation === "Primary Coordinator") {
        return (primary = (
          <div className="card">
            <div className="proImage">
              <img
                src={`${
                  profile
                    ? `${image.main_image_url}/${profile.image}`
                    : "./images/profile-image.png"
                }`}
                width="100%"
                alt={profile.name}
              />
            </div>
            <div className="profile-info text-center">
              <h6>{profile.name}</h6>
              <span>{profile.qualification}</span>
              <br />
              <span>{profile.designation}</span>
            </div>
            <div className="profile-divider">
              <div className="social-link">
                {profile.facebookurl ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={profile.facebookurl}
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                ) : (
                  ""
                )}
                {profile.instagramurl ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${profile.instagramurl}`}
                  >
                    <i className="fa fa-instagram mx-3"></i>
                  </a>
                ) : (
                  ""
                )}
                {profile.twitterurl ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={profile.twitterurl}
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ));
      }
      if (profile.designation === "Secondary Coordinator") {
        return (secondary = (
          <div className="card">
            <div className="proImage">
              <img
                src={`${
                  profile
                    ? `${image.main_image_url}/${profile.image}`
                    : "./images/profile-image.png"
                }`}
                width="100%"
                alt={profile.name}
              />
            </div>
            <div className="profile-info text-center">
              <h6>{profile.name}</h6>
              <span>{profile.qualification}</span>
              <br />
              <span>{profile.designation}</span>
            </div>
            <div className="profile-divider">
              <div className="social-link">
                {profile.facebookurl ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={profile.facebookurl}
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                ) : (
                  ""
                )}
                {profile.instagramurl ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${profile.instagramurl}`}
                  >
                    <i className="fa fa-instagram mx-3"></i>
                  </a>
                ) : (
                  ""
                )}
                {profile.twitterurl ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={profile.twitterurl}
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ));
      }
    });
  }
  return (
    <div className="acadMembers">
      <div className="acadMembers">
        <div className="row justify-content-center">
          <div className="col-md-4">{preprimary}</div>
          <div className="col-md-4">{primary}</div>
          <div className="col-md-4">{secondary}</div>
        </div>
      </div>
    </div>
  );
};

export default Coordinator;
