import React from "react";
import Banner from "./Banner";
import MsgFrom from "./MsgFrom";
import Calendar from "./Notice/Calendar";
import Notice from "./Notice/Notice";

function Content() {
  return (
    <div>
      <Banner />
      <MsgFrom />
      <Notice />
      <Calendar />
    </div>
  );
}

export default Content;
