import axios from "axios";
import React, { useEffect, useState } from "react";
import Coordinator from "./MemberProfiles/Coordinator";
import Principal from "./MemberProfiles/Principal";
import SubTeachers from "./MemberProfiles/SubTeachers";

const ProfileSection = () => {
  const [loading, setLoading] = useState(true);
  const [acadteam, setAcadteam] = useState([]);
  const [image, setImage] = useState({});

  useEffect(() => {
    axios.get("https://admin.riaschool.edu.np/api/v1/profiles").then((res) => {
      if (res.data.status === 200) {
        setAcadteam(res.data.data.emprofile);
        setImage(res.data.data.attributes);
      }
      setLoading(false);
    });
  }, []);

  return (
    <section className="my-3" id="academic_team">
      <div className="container">
        <h1>Academic Team</h1>
        <h3>Academic team is a pillar of an academic institution.</h3>

        <Principal profiles={acadteam} image={image} />
        <Coordinator profiles={acadteam} image={image} />
        <SubTeachers profiles={acadteam} image={image} />
      </div>
    </section>
  );
};

export default ProfileSection;
