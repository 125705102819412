import axios from "axios";
import React, { useEffect, useState } from "react";

const Uniform = () => {
  const [loading, setLoading] = useState(true);
  const [schedules, setSchedules] = useState(false);

  useEffect(() => {
    axios.get("https://admin.riaschool.edu.np/api/v1/schedule").then((res) => {
      if (res.data.status === 200) {
        setSchedules(res.data.data.schedules);
      }
      setLoading(false);
    });
  }, []);

  let schedule;
  if (loading) {
    return (
      <div className="text-center align-item-center my-3">
        <div
          className="spinner-border text-success"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    schedule = schedules.map((scheduleData, idx) => {
      return (
        <div className="col-md-6" key={idx}>
          <h4>{scheduleData.title}</h4>
          <div
            dangerouslySetInnerHTML={{ __html: `${scheduleData.description}` }}
          ></div>
        </div>
      );
    });
  }

  return (
    <div>
      <section id="uniform">
        <div className="container">
          <div className="row">
            {schedule}
            {/* <div className="col-md-6">
              <table className="table table-info table-striped">
                <thead>
                  <tr>
                    <th>Sunday/Monday/Wednesday/Thursday</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Ash Gray Pants & Skirt</td>
                  </tr>
                  <tr>
                    <td>White Shirt</td>
                  </tr>
                  <tr>
                    <td>Maroon Sweater/Blazer</td>
                  </tr>
                  <tr>
                    <td>Maroon Tie and School Belt</td>
                  </tr>
                  <tr>
                    <td>Black Shoes and Gray Socks</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <table className="table table-info table-striped">
                <thead>
                  <tr>
                    <th>Tuesday/Friday</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>House Dress</td>
                  </tr>
                  <tr>
                    <td>House wise Tracksuit Set</td>
                  </tr>
                  <tr>
                    <td>Canvas Shoes and White Socks</td>
                  </tr>
                  <tr>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Uniform;
