import axios from "axios";
import React, { useEffect, useState } from "react";

function Banner() {
  const [loading, setLoading] = useState(true);
  const [banners, setBanners] = useState([]);
  const [image, setImage] = useState({});

  useEffect(() => {
    axios
      .get("https://admin.riaschool.edu.np/api/v1/banners")
      .then((res) => {
        if (res.status === 200) {
          setBanners(res.data.data.banners);
          setImage(res.data.data.attributes);
        }
        setLoading(false);
      })
      .catch((err) => {});
  }, []);

  let bannerSlide;
  if (loading) {
    return (
      <div className="text-center align-item-center my-3">
        <div
          className="spinner-border text-success"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    bannerSlide = banners.map((banner, idx) => {
      return (
        <div key={idx} className="carousel-item active">
          <div
            className="home-banner home-banner-1"
            style={{
              backgroundImage: `url(${image.main_image_url}/${banner.image})`,
            }}
          >
            <div className="home-banner-text">
              {/* <h1>{banner.title}</h1>
              <h2>50% Discount For This Season</h2> */}
              {/* <a href="#" className="btn btn-danger text-uppercase mt-4">
                our Products
              </a> */}
            </div>
          </div>
        </div>
      );
    });
  }
  return (
    <div>
      {/* <!-- Home Slider Scetion --> */}
      <section id="home-slider" className="home-slider overflow-hidden">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
          </div>
          <div className="carousel-inner">{bannerSlide}</div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true">
              <span className="prev slider-icon">&#10094;</span>
            </span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true">
              <span className="next slider-icon">&#10095;</span>
            </span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section>
    </div>
  );
}

export default Banner;
