import React from "react";

const Calendar = () => {
  return (
    <div className="container">
      <span
        style={{
          fontSize: "30px",
          fontWeight: "700",
          color: "#00adb5",
        }}
      >
        Academic Calendar
      </span>
      <div className="row my-3">
        <div className="hero">
          <div id="calendar"></div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
