import React from "react";

const Principal = ({ profiles, image }) => {
  let member;
  let principal;
  let vicePrincipal;
  if (!profiles) {
    return (
      <div className="text-center align-item-center my-3">
        <div
          className="spinner-border text-success"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    profiles.map((profile, idx) => {
      if (profile.designation === "Principal") {
        return (principal = (
          <div className="card">
            <div className="proImage">
              <img
                src={`${
                  profile
                    ? `${image.main_image_url}/${profile.image}`
                    : "./images/profile-image.png"
                }`}
                width="100%"
                alt={profile.name}
              />
            </div>
            <div className="profile-info text-center">
              <h6>{profile.name}</h6>
              <br />
              <span>{profile.designation}</span>
            </div>
            <div className="profile-divider">
              <div className="social-link">
                {profile.facebookurl ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={profile.facebookurl}
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                ) : (
                  ""
                )}
                {profile.instagramurl ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${profile.instagramurl}`}
                  >
                    <i className="fa fa-instagram mx-3"></i>
                  </a>
                ) : (
                  ""
                )}
                {profile.twitterurl ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={profile.twitterurl}
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ));
      }

      {
        /* <!-- Vice principal -- */
      }
      if (profile.designation === "Vice Principal") {
        return (vicePrincipal = (
          <div className="card">
            <div className="proImage">
              <img
                src={`${
                  profile
                    ? `${image.main_image_url}/${profile.image}`
                    : "./images/profile-image.png"
                }`}
                width="100%"
                alt={profile.name}
              />
            </div>
            <div className="profile-info text-center">
              <h6>{profile.name}</h6>
              <span>{profile.qualification || ""}</span>
              <br />
              <span>{profile.designation}</span>
            </div>
            <div className="profile-divider">
              <div className="social-link">
                {profile.facebookurl ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={profile.facebookurl}
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                ) : (
                  ""
                )}
                {profile.instagramurl ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${profile.instagramurl}`}
                  >
                    <i className="fa fa-instagram mx-3"></i>
                  </a>
                ) : (
                  ""
                )}
                {profile.twitterurl ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={profile.twitterurl}
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ));
      }
    });
  }
  return (
    <div className="container">
      <div className="row">
        {/* <div className="acadMembers">
          <div className="d-flex flex-wrap justify-content-between">
            {member}
          </div>
        </div> */}
        {/* <div className="col-md-6">
          <div className="acadMembers" style={{ float: "right" }}>
            {principal}
          </div>
        </div> */}
        <div className="acadMembers">
          <div className="row justify-content-center">
            <div className="col-md-4">{principal}</div>
            <div className="col-md-4">{vicePrincipal}</div>
          </div>
        </div>

        {/* <div className="col-md-6">
          <div className="acadMembers">{vicePrincipal}</div>
        </div> */}
        {/* <div className="acadMembers">
        <div className="d-flex justify-content-between">{member}</div>
      </div> */}
      </div>
    </div>
  );
};

export default Principal;
