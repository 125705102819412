import axios from "axios";
import React, { useEffect, useState } from "react";

const AcadRules = () => {
  const [loading, setLoading] = useState(true);
  const [acadrules, setAcadrules] = useState([]);

  useEffect(() => {
    axios.get("https://admin.riaschool.edu.np/api/v1/academic").then((res) => {
      if (res.status === 200) {
        setAcadrules(res.data.data.academics);
      }
      setLoading(false);
    });
  }, []);

  let academic;

  if (loading) {
    return (
      <div className="text-center align-item-center my-3">
        <div
          className="spinner-border text-success"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    academic = acadrules.map((acadrule, idx) => {
      return (
        <div className="grules my-2" key={idx}>
          <h2>{acadrule.title}</h2>
          <div className="rule-info">
            {/* <h3 className="text-center">Teaching Methodology</h3>
            <h3>Methodology for Pre-Primary</h3> */}
            <div
              dangerouslySetInnerHTML={{ __html: `${acadrule.description}` }}
            ></div>
          </div>
        </div>
      );
    });
  }

  return (
    <section>
      <div className="container">
        <div className="row">
          {academic}
          {/* <div className="grules">
            <h2>Major Academic Activities</h2>
            <div className="rule-info">
              <h3 className="text-center">Teaching Methodology</h3>
              <h3>Methodology for Pre-Primary</h3>
              Pre-Primary block at RIA focuses on theme based learning
              activities. It focuses on two ways communication. The age group of
              this level is 2.5 to 5 years. Students are involved in learning
              atmosphere through age appropriate cultural, eye-hand condition,
              ECA to enhance a child’s language, communication, moral and social
              values. Method of Colour and shapes are implemented in
              teaching-learning methods. We also focus on story, art, craft,
              music, yoga, outdoor and indoor learning, physical education. Our
              academic staff are well trained and have activity-based learning
              and teaching methods.
              <h3>Methodology for Primary</h3>
              The age group of this level is 6 to 10 years. We conduct teaching
              learning pedagogy considering development of each child
              emotionally, socially, physically, artistically and academically.
              This level is considered as the strong foundation of basic skills,
              deep sense of self and moral values which are global challenges in
              the present context. Best on integrated curriculum, students are
              encouraged to bring out their latent talent under the supportive
              guidance of our caring teachers and staffs. Our ultimate goal is
              to educate the heart of children through child centered teaching
              and learning methodology.
              <h3>Methodology for Secondary</h3>
              Our school has adopted innovative and advanced teaching–learning
              methodologies in an exclusive way. The vision, 'the inquisitive
              minds need creative teaching' is the rationale behind this effort.
              Its educational activities are conducted by a large number of
              qualified and experienced teaching faculties, supported and
              complemented by visiting experts of strong academic and
              professional background. The teaching is entirely interactive and
              student–centered. The teachers are supported by students' full
              participation in group discussion, project assignments and
              field–trips. Apart from these, audio–visual aid, Chart, models,
              multi–media, projectors etc. are used to make teaching and
              learning more interesting, effective and purposeful.
            </div>
          </div> */}
        </div>
        {/* <div className="row my-3">
          <div className="col-md-6">
            <div className="grules">
              <h2>Guest Teachers</h2>
              <span className="para">
                In order to make students acquaint with latest works and
                theories on the subjects, guest teachers or writers who are
                reputed on the subjects will be invited to the school as guest
                teachers to give the talks to our students.
              </span>

              <h2>Special Classes for Merit Students</h2>
              <span className="para">
                Merit students selected from their terminal exams are given
                special classes with the objective that they will be ready to do
                their final exam with more confidence and with more knowledge.
              </span>

              <h2>Use of Audio–Visuals</h2>
              <span className="para">
                In order to enhance the teaching skills and make learning more
                comprehensible, RIA constantly takes the help of modern teaching
                techniques. Experienced teachers at RIA make students grasp many
                complex lessons with ease through the use of appropriate
                multimedia presentations and various audio–visual classes.
              </span>

              <h2>Use of Audio–Visuals</h2>
              <span className="para">
                In order to enhance the teaching skills and make learning more
                comprehensible, RIA constantly takes the help of modern teaching
                techniques. Experienced teachers at RIA make students grasp many
                complex lessons with ease through the use of appropriate
                multimedia presentations and various audio–visual classes.
              </span>

              <h2>Project Works</h2>
              <span className="para">
                Students are required to complete and submit projects reports
                related to subjects based on class work and data collection from
                field visits under the guidance of the subject teachers.
                Evaluation marks are given for such project works which will
                eventually be added to each terminal examination.
              </span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="grules">
              <h2>Curriculum</h2>
              <h3>Homework</h3>
              <span className="para">
                RIA believes that revision is the key technique to remember the
                textual concepts. That is why we always assign students as much
                homework as possible. Homework is checked and returned promptly
                each day for each subject. This helps the students to keep pace
                with their studies.
              </span>

              <h3>Examination</h3>
              <span className="para">
                In order to assume desirable performance from the students, the
                school conducts four major terminal examinations and a number of
                formal and informal examinations. Each student's learning
                achievement tendency is consistently monitored. All students are
                required to maintain desired level of performance in all
                internal assignments, project works, class works and home works
                etc. Progress of students in all academic fields will be
                informed to their guardian or parent immediately.
              </span>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default AcadRules;
