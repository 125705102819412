import axios from "axios";
import React, { useEffect, useState } from "react";

const Awards = () => {
  const [loading, setLoading] = useState(true);
  const [awards, setFacilities] = useState([]);
  const [awardsImg, setAwardsImg] = useState({});

  useEffect(() => {
    axios.get("https://admin.riaschool.edu.np/api/v1/awards").then((res) => {
      if (res.status === 200) {
        setFacilities(res.data.data.awards);
        setAwardsImg(res.data.data.attributes);
      }
      setLoading(false);
    });
  }, []);

  let award;
  if (loading) {
    return (
      <div className="text-center align-item-center my-3">
        <div
          className="spinner-border text-success"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    award = awards.map((awardData, idx) => {
      return awardData.title !== "Awards & ECA" ? (
        <span className="my-3" key={idx}>
          <h1>{awardData.title}</h1>
          <div className="cca-objectives">
            <div className="image">
              <img
                src={`${awardsImg.main_image_url}/${awardData.image}`}
                alt={awardData.title}
              />
            </div>
            <span
              dangerouslySetInnerHTML={{
                __html: `${awardData.description}`,
              }}
            />
          </div>
        </span>
      ) : (
        ""
      );
    });
  }
  return (
    <div>
      <section id="cca-eca">
        <div className="container">
          <div className="row">{award}</div>
        </div>
      </section>
    </div>
  );
};

export default Awards;
