import React from "react";
import AcadRules from "./AcadRules";

import ProfileSection from "./ProfileSection";

const Academic = () => {
  return (
    <div>
      <ProfileSection />
      <AcadRules />
    </div>
  );
};

export default Academic;
