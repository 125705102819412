import axios from "axios";
import React, { useEffect, useState } from "react";

const Rules = () => {
  const [loading, setLoading] = useState(true);
  const [grules, setGrules] = useState([]);

  useEffect(() => {
    axios.get("https://admin.riaschool.edu.np/api/v1/grule").then((res) => {
      if (res.status === 200) {
        setGrules(res.data.data.grules);
      }
      setLoading(false);
    });
  }, []);

  let generalRule;
  if (loading) {
    return (
      <div className="text-center align-item-center my-3">
        <div
          className="spinner-border text-success"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    generalRule = grules.map((grule, idx) => {
      return (
        <div className="grules my-3" key={idx}>
          <h2>{grule.title}</h2>
          <div className="rule-info">
            <div
              dangerouslySetInnerHTML={{ __html: `${grule.description}` }}
            ></div>
          </div>
        </div>
      );
    });
  }

  return (
    <div>
      <section id="rules">
        <div className="container my-4">
          <div className="row">{generalRule}</div>
        </div>
      </section>
    </div>
  );
};

export default Rules;
