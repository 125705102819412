import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import AdmissionForm from "./AdmissionForm";

function Header() {
  const [loading, setLoading] = useState(true);
  const [setting, setSetting] = useState({});
  const [imgUrl, setImgUrl] = useState({});
  const [notices, setNotices] = useState([]);

  const fetchData = async () => {
    await axios
      .get("https://admin.riaschool.edu.np/api/v1/setting")
      .then((res) => {
        if (res.status === 200) {
          setSetting(res.data.data.setting);
          setImgUrl(res.data.data.attributes);
        }
        setLoading(false);
      });

    await axios
      .get("https://admin.riaschool.edu.np/api/v1/notice")
      .then((res) => {
        if (res.status === 200) {
          setNotices(res.data.data.notices);
        }
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  // let scrollNotice;
  // if (!notices || notices === undefined || notices.length === 0) {
  //   return "";
  // } else {
  //   scrollNotice = notices.map((notice, i) => {
  //     if (notice.notice === "sn1") {
  //       return (
  //         <span key={i}>
  //           <div
  //             dangerouslySetInnerHTML={{ __html: `${notice.description}` }}
  //           ></div>
  //         </span>
  //       );
  //     }
  //   });
  // }

  return (
    <>
      <section className="header">
        <div className="container text-center">
          <div className="row">
            <div className="col-md-2">
              <div className="logo-left d-none d-md-block">
                {!setting || !setting.logo ? (
                  <div className="text-center align-item-center my-3">
                    <div
                      className="spinner-border text-success"
                      style={{ width: "3rem", height: "3rem" }}
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <img
                    src={`${imgUrl.logo_url}/${setting.logo}`}
                    alt="logo"
                    width="100%"
                  />
                )}
              </div>
            </div>
            <div className="col-md-8">
              <h1>{!setting ? "" : setting.sitetitle || ""}</h1>
              <h3>({!setting ? "" : setting.meta_title || ""})</h3>
              <span>{!setting ? "" : setting.short_content || ""}</span>
            </div>
            <div className="col-md-2 justify-content-center">
              <div className="logo-right d-none d-md-block">
                <img src="./images/rsp.png" alt="logo" width="100%" />
                <AdmissionForm />
              </div>
            </div>
            {/* Register Form */}
          </div>
        </div>
      </section>
      {/* Scroll New Section  */}
      <section className="scroll-news">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 text-center bg-danger">
              <span className="news-heading">Latest New</span>
            </div>
            <div className="col-md-10">
              <div className="text-update">
                {!notices || notices === undefined || notices.length === 0
                  ? ""
                  : notices.map((notice, i) => {
                      return notice.notice === "sn1" ? (
                        <span key={i}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `${notice.description}`,
                            }}
                          ></div>
                        </span>
                      ) : (
                        ""
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Navigation Nav Bar Section  */}
      <section className="Menu-Nav">
        <nav className="navbar navbar-expand-lg navbar-dark bg-success">
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${(navData) =>
                      navData.isActive ? "active" : ""}`}
                    to="/"
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${(navData) =>
                      navData.isActive ? "active" : ""}`}
                    to="/about"
                  >
                    About
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${(navData) =>
                      navData.isActive ? "active" : ""}`}
                    to="/academic"
                  >
                    Academic
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${(navData) =>
                      navData.isActive ? "active" : ""}`}
                    to="/cca"
                  >
                    CCA & ECA
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${(navData) =>
                      navData.isActive ? "active" : ""}`}
                    to="/facilities"
                  >
                    Facilities
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${(navData) =>
                      navData.isActive ? "active" : ""}`}
                    to="/awards"
                  >
                    Activities and Awards
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${(navData) =>
                      navData.isActive ? "active" : ""}`}
                    to="/gallery"
                  >
                    Gallery
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${(navData) =>
                      navData.isActive ? "active" : ""}`}
                    to="/contact"
                  >
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section>
    </>
  );
}

export default Header;
