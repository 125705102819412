import axios from "axios";
import React, { useEffect, useState } from "react";

const Contact = () => {
  const [loading, setLoading] = useState(true);
  const [setting, setSetting] = useState({});
  const [values, setValues] = useState({
    name: "",
    contact: "",
    email: "",
    message: "",
  });

  const [flashmsg, setFlashmsg] = useState({});
  const [showFlash, setShowFlash] = useState(false);

  useEffect(() => {
    axios.get("https://admin.riaschool.edu.np/api/v1/setting").then((res) => {
      if (res.status === 200) {
        setSetting(res.data.data.setting);
      }
    });
  }, []);

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let mail = { ...values };
    axios
      .post("https://admin.riaschool.edu.np/api/v1/sendmail", mail)
      .then((res) => {
        if (res.status === 200) {
          setFlashmsg(res.data.message);
          setShowFlash(true);
          setValues({
            name: "",
            contact: "",
            email: "",
            message: "",
          });
        }
      })
      .catch((err) => {
        console.log("Err", err);
      });
    setTimeout(() => {
      setShowFlash(false);
    }, 5000);
  };

  return (
    <div className="container-fluid">
      <div className="row my-3">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3531.5880360955257!2d85.35225811744385!3d27.730003200000013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb195ffd7b1a3d%3A0x95522ef6fb71e3c4!2sReliance%20International%20Academy!5e0!3m2!1sen!2snp!4v1641637993276!5m2!1sen!2snp"
          width="100"
          height="450"
          style={{ border: "0" }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
      <div className="row my-3">
        <div className="col-md-6">
          <div className="card h-100">
            <div className="card-header bg-success text-center text-white">
              <h4>Inquiry Message</h4>
            </div>
            {!showFlash || showFlash === false ? (
              ""
            ) : (
              <div className="alert alert-success" role="alert">
                {flashmsg}
              </div>
            )}
            <div className="card-body">
              {/* <!-- Bootstrap 5 starter form --> */}
              <form
                onSubmit={handleSubmit}
                id="contactForm"
                data-sb-form-api-token="API_TOKEN"
              >
                {/* <!-- Name input --> */}
                <div className="mb-3">
                  <label className="form-label" htmlFor="name">
                    Name
                  </label>
                  <input
                    className="form-control"
                    id="name"
                    name="name"
                    type="text"
                    value={values.name || ""}
                    placeholder="Name"
                    data-sb-validations="required"
                    onChange={handleChange}
                  />
                  <div
                    className="invalid-feedback"
                    data-sb-feedback="name:required"
                  >
                    Name is required.
                  </div>
                </div>

                {/* Contact Number */}
                <div className="mb-3">
                  <label className="form-label" htmlFor="number">
                    Contact Number
                  </label>
                  <input
                    className="form-control"
                    id="number"
                    name="contact"
                    type="text"
                    value={values.contact || ""}
                    placeholder="Number"
                    data-sb-validations="required"
                    onChange={handleChange}
                  />
                  <div
                    className="invalid-feedback"
                    data-sb-feedback="name:required"
                  >
                    Name is required.
                  </div>
                </div>

                {/* <!-- Email address input --> */}
                <div className="mb-3">
                  <label className="form-label" htmlFor="emailAddress">
                    Email Address
                  </label>
                  <input
                    className="form-control"
                    id="emailAddress"
                    name="email"
                    value={values.email || ""}
                    type="email"
                    placeholder="Email Address"
                    data-sb-validations="required, email"
                    onChange={handleChange}
                  />
                  <div
                    className="invalid-feedback"
                    data-sb-feedback="emailAddress:required"
                  >
                    Email Address is required.
                  </div>
                  <div
                    className="invalid-feedback"
                    data-sb-feedback="emailAddress:email"
                  >
                    Email Address Email is not valid.
                  </div>
                </div>

                {/* <!-- Message input --> */}
                <div className="mb-3">
                  <label className="form-label" htmlFor="message">
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    type="text"
                    value={values.message || ""}
                    placeholder="Message"
                    style={{ height: "10rem" }}
                    data-sb-validations="required"
                    onChange={handleChange}
                  ></textarea>
                  <div
                    className="invalid-feedback"
                    data-sb-feedback="message:required"
                  >
                    Message is required.
                  </div>
                </div>

                {/* <!-- Form submissions success message --> */}
                <div className="d-none" id="submitSuccessMessage">
                  <div className="text-center mb-3">
                    Form submission successful!
                  </div>
                </div>

                {/* <!-- Form submissions error message --> */}
                <div className="d-none" id="submitErrorMessage">
                  <div className="text-center text-danger mb-3">
                    Error sending message!
                  </div>
                </div>

                {/* <!-- Form submit button --> */}
                <div className="d-grid">
                  <button
                    className="btn btn-primary btn-lg"
                    id="submitButton"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card h-100">
            {/* <!-- Links --> */}
            <div className="card-header text-center text-white bg-danger">
              <h4 className="text-uppercase fw-bold">Contact</h4>
            </div>
            <div className="card-body">
              <p>
                <i className="fas fa-home me-3"></i>{" "}
                {!setting ? "" : setting.sitetitle || ""}
              </p>
              <p>
                <i className="fas fa-map-marker-alt me-3"></i>
                {!setting ? "" : setting.address || ""}
              </p>
              <p>
                <i className="fas fa-envelope me-3"></i>
                <a href="">{!setting ? "" : setting.siteemail || ""}</a>
              </p>
              <p>
                <i className="fas fa-phone me-3"></i>{" "}
                {!setting ? "" : setting.phone || ""}
              </p>
              <div>
                <h4 className="text-danger">
                  Other Wings under Reliance Education Network
                </h4>
                <p>
                  <a href="http://www.rianepal.edu.np/" className="text-reset">
                    Reliance International Academy (+2)
                  </a>
                </p>

                <p>
                  <a
                    href="http://www.riacollege.edu.np/"
                    className="text-reset"
                  >
                    Reliance College (BBS, BCA, BA, MBS)
                  </a>
                </p>

                <p>
                  <a href="http://www.rps.edu.np/" className="text-reset">
                    Reliance Public School
                  </a>
                </p>

                <p>
                  <a href="http://reliancecoed.edu.np/" className="text-reset">
                    Reliance Co&ndash;Ed. School
                  </a>
                </p>

                <p>
                  <a href="" className="text-reset">
                    Reliance Kids Home
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
