import axios from "axios";
import React, { useEffect, useState } from "react";

const NoticeCard = () => {
  const [loading, setLoading] = useState(true);
  const [notices, setNotices] = useState([]);

  useEffect(() => {
    axios.get("https://admin.riaschool.edu.np/api/v1/notice").then((res) => {
      if (res.status === 200) {
        setNotices(res.data.data.notices);
      }
      setLoading(false);
    });
  }, []);

  let noticeData;
  if (loading || !notices || notices.length === 0) {
    return "";
  } else {
    noticeData = notices.map((notice, idx) => {
      if (notice.notice === "nb1") {
        return (
          <div
            className="card bg-warning mb-3"
            style={{ maxWidth: " 540px" }}
            key={idx}
          >
            <div
              className="card-header text-center"
              style={{ fontWeight: "bold" }}
            >
              {notice.title}
            </div>
            <div className="card-body" style={{ fontWeight: "bold" }}>
              <span
                style={{ overFlow: "auto" }}
                dangerouslySetInnerHTML={{ __html: `${notice.description}` }}
              ></span>
            </div>
          </div>
        );
      } else {
        return "";
      }
    });
  }
  return <section>{noticeData}</section>;
};

export default NoticeCard;
