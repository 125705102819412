import axios from "axios";
import React, { useEffect, useState } from "react";

const MissionVision = () => {
  const [loading, setLoading] = useState(true);
  const [coldata, setColdata] = useState([]);
  // Image Slider
  const [setting, setSetting] = useState({});
  const [image, setImage] = useState({});

  const fetchData = () => {
    axios.get("https://admin.riaschool.edu.np/api/v1/about").then((res) => {
      if (res.status === 200) {
        setColdata(res.data.data.abouts);
      }
      setLoading(false);
    });

    axios
      .get("https://admin.riaschool.edu.np/api/v1/setting")
      .then((res) => {
        if (res.status === 200) {
          setSetting(res.data.data.setting);
          setImage(res.data.data.attributes);
        }
        setLoading(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchData();
  }, []);

  console.log("Setting", setting);
  console.log("Img", image);

  let missionData;
  if (loading) {
    return (
      <div className="text-center align-item-center my-3">
        <div
          className="spinner-border text-success"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    missionData = coldata.map((data, idx) => {
      return (
        <div className="accordion-item" key={idx}>
          <h2 className="accordion-header" id={idx}>
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#col${data.id}`}
              aria-expanded="true"
              aria-controls={`col${data.id}`}
            >
              {data.title}
            </button>
          </h2>
          <div
            id={`col${data.id}`}
            className={`accordion-collapse collapse ${
              idx === 0 ? "show" : null
            }`}
            aria-labelledby={idx}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
            </div>
          </div>
        </div>
      );
    });
  }

  let bannerSlide;
  // if (loading) {
  //   return (
  //     <div className="text-center align-item-center my-3">
  //       <div
  //         className="spinner-border text-success"
  //         style={{ width: "3rem", height: "3rem" }}
  //         role="status"
  //       >
  //         <span className="visually-hidden">Loading...</span>
  //       </div>
  //     </div>
  //   );
  // } else {
  //   bannerSlide = banners.map((banner, idx) => {
  //     return (
  //       <div key={idx} className="carousel-item active">
  //         <img
  //           src={`${image.main_image_url}/${banner.image}`}
  //           // className="d-block w-100"
  //           className="responsive-image"
  //           width="500px"
  //           height="500px"
  //           alt="slider"
  //         />
  //       </div>
  //     );
  //   });
  // }

  console.log("Img", setting);
  return (
    <div>
      <section className="container" id="mission">
        <div className="row">
          <div className="col-md-6" id="mission">
            <div className="container">
              <div className="accordion" id="accordionExample">
                {missionData}
              </div>
            </div>
          </div>
          <div className="col-md-6" id="acad-slider">
            <div className="container">
              {!setting || !setting.org_image ? (
                ""
              ) : (
                <img
                  src={`${image.org_image_url}/${setting.org_image}`}
                  alt="logo"
                  width="100%"
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MissionVision;
