import axios from "axios";
import React, { useEffect, useState } from "react";

const Gallery = () => {
  const [loading, setLoading] = useState(true);
  const [galleries, setGalleries] = useState([]);
  const [image, setImage] = useState({});

  useEffect(() => {
    axios.get("https://admin.riaschool.edu.np/api/v1/galleries").then((res) => {
      if (res.status === 200) {
        setGalleries(res.data.data.galleries);
        setImage(res.data.data.attributes);
      }
      setLoading(false);
    });
  }, []);

  let galImage;

  if (loading) {
    return (
      <div className="text-center align-item-center my-3">
        <div
          className="spinner-border text-success"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    galImage = galleries.map((gallery, idx) => {
      return (
        <div key={idx}>
          <h2 className="text-center text-danger">{gallery.title}</h2>
          {!gallery.galleries ? (
            <div className="text-center align-item-center my-3">
              <div
                className="spinner-border text-success"
                style={{ width: "3rem", height: "3rem" }}
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : !gallery.galleries.map ? (
            ""
          ) : (
            gallery.galleries.map((galData, idx) => {
              return (
                <div className="row" key={idx}>
                  <h4 className="text-success">{galData.title}</h4>
                  {galData.pictures.map((picture, idx) => {
                    return (
                      <div className="col-md-3" key={idx}>
                        <a
                          href="#!"
                          data-bs-toggle="modal"
                          data-bs-target={`#modal${picture.id}`}
                        >
                          <img
                            src={`${image.main_image_url}/${picture.image}`}
                            alt="img"
                            className="img-thumbnail rounded"
                            width="100%"
                            height="100%"
                          />
                        </a>

                        <div
                          className="modal fade"
                          id={`modal${picture.id}`}
                          tabIndex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <img
                                  src={`${image.main_image_url}/${picture.image}`}
                                  alt="img"
                                  className="img-fluid img-thumbnail rounded"
                                  width="100%"
                                  height="100%"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
          )}
        </div>
      );
    });
  }

  return (
    <div className="container-fluid my-3">
      <div className="row">{galImage}</div>
      {/* <div className="row">
        <div className="col-md-3">
          
          <a
            href=""
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            <img
              src="/images/2.jfif"
              alt="img"
              className="img-thumbnail rounded"
              width="100%"
              height="100%"
            />
          </a>

          
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <img
                    src="/images/2.jfif"
                    alt="img"
                    className="img-fluid img-thumbnail rounded"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Gallery;
