import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const AcadMembers = () => {
  const [loading, setLoading] = useState(true);
  const [bods, setBods] = useState([]);
  const [bodImg, setBodimg] = useState({});

  const [schoolmgmts, setSchoolmgmt] = useState([]);
  const [schoolmgmtImg, setSchoolmgmtimg] = useState({});

  const [acadcommittee, setAcadcommittee] = useState([]);
  const [acadcommitteeimg, setAcadcommitteeimg] = useState({});

  const [ecacommittee, setEcacommittee] = useState([]);
  const [ecacommitteeimg, setEcacommitteeimg] = useState({});

  const [examcommittee, setExamcommittee] = useState([]);
  const [examcommitteeimg, setExamcommitteeimg] = useState({});

  const [counsel, setCounsel] = useState([]);
  const [counselimg, setCounselimg] = useState({});

  const fetchData = () => {
    const bodsAPI = axios.get("https://admin.riaschool.edu.np/api/v1/bod");
    const schoolmgmtAPI = axios.get(
      "https://admin.riaschool.edu.np/api/v1/schoolmgmt"
    );
    const acadcommitteeAPI = axios.get(
      "https://admin.riaschool.edu.np/api/v1/acadcommitte"
    );
    const ecacommitteeAPI = axios.get(
      "https://admin.riaschool.edu.np/api/v1/ecacommittee"
    );
    const examcommitteeAPI = axios.get(
      "https://admin.riaschool.edu.np/api/v1/examcommittee"
    );
    const counselAPI = axios.get(
      "https://admin.riaschool.edu.np/api/v1/counsel"
    );

    axios
      .all([
        bodsAPI,
        schoolmgmtAPI,
        acadcommitteeAPI,
        ecacommitteeAPI,
        examcommitteeAPI,
        counselAPI,
      ])
      .then(
        axios.spread((...allData) => {
          const allBods = allData[0].data.data.bods;
          const allBodsimg = allData[0].data.data.attributes;
          setBods(allBods);
          setBodimg(allBodsimg);

          const allSchools = allData[1].data.data.schoolmgmts;
          const allSchoolsimg = allData[1].data.data.attributes;
          setSchoolmgmt(allSchools);
          setSchoolmgmtimg(allSchoolsimg);

          const allacadcommittee = allData[2].data.data.acadcommittes;
          const allacadcommitteeImg = allData[2].data.data.attributes;
          setAcadcommittee(allacadcommittee);
          setAcadcommitteeimg(allacadcommitteeImg);

          const allecacommittee = allData[3].data.data.ecacommittes;
          const allecacommitteeImg = allData[3].data.data.attributes;
          setEcacommittee(allecacommittee);
          setEcacommitteeimg(allecacommitteeImg);

          const allexamcommittee = allData[4].data.data.examcommittee;
          const allexamcommitteeImg = allData[4].data.data.attributes;
          setExamcommittee(allexamcommittee);
          setExamcommitteeimg(allexamcommitteeImg);

          const allcounsel = allData[5].data.data.counsels;
          const allcounselImg = allData[5].data.data.attributes;
          setCounsel(allcounsel);
          setCounselimg(allcounselImg);
        })
      );
  };

  useEffect(() => {
    fetchData();
    setLoading(false);
  }, []);

  let bodCommittee;
  let schoolMgmt;
  let acadMgmt;
  let eca;
  let exam;
  let counselling;

  if (loading) {
    return (
      <div className="text-center align-item-center my-3">
        <div
          className="spinner-border text-success"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    if (bods) {
      bodCommittee = bods.map((bod, idx) => {
        return (
          <div className="col-md-3" key={idx}>
            <div className="card">
              <div className="proImage">
                <img
                  alt=""
                  src={`${bodImg.main_image_url}/${bod.image}`}
                  width="100%"
                />
              </div>
              <div className="profile-info text-center">
                <h6>{bod.name}</h6>
                <br />
                <span>{bod.designation}</span>
              </div>
              <div className="profile-divider">
                <div className="social-link">
                  {bod.facebookurl ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={bod.facebookurl}
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  ) : (
                    ""
                  )}
                  {bod.instagramurl ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${bod.instagramurl}`}
                    >
                      <i className="fab fa-instagram mx-3"></i>
                    </a>
                  ) : (
                    ""
                  )}
                  {bod.twitterurl ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={bod.twitterurl}
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }

    if (schoolmgmts) {
      schoolMgmt = schoolmgmts.map((schoolmgmt, idx) => {
        return (
          <div className="col-md-3" key={idx}>
            <div className="card">
              <div className="proImage">
                <img
                  alt=""
                  src={`${schoolmgmtImg.main_image_url}/${schoolmgmt.image}`}
                  width="100%"
                />
              </div>
              <div className="profile-info text-center">
                <h6>{schoolmgmt.name}</h6>
                <br />
                <span>{schoolmgmt.designation}</span>
              </div>
              <div className="profile-divider">
                <div className="social-link">
                  {schoolmgmt.facebookurl ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={schoolmgmt.facebookurl}
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  ) : (
                    ""
                  )}
                  {schoolmgmt.instagramurl ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${schoolmgmt.instagramurl}`}
                    >
                      <i className="fa fa-instagram mx-3"></i>
                    </a>
                  ) : (
                    ""
                  )}
                  {schoolmgmt.twitterurl ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={schoolmgmt.twitterurl}
                    >
                      <i className="fa fa-twitter"></i>
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }

    if (acadcommittee) {
      acadMgmt = acadcommittee.map((acad, idx) => {
        return (
          <div className="col-md-3" key={idx}>
            <div className="card">
              <div className="proImage">
                <img
                  alt=""
                  src={`${acadcommitteeimg.main_image_url}/${acad.image}`}
                  width="100%"
                />
              </div>
              <div className="profile-info text-center">
                <h6>{acad.name}</h6>
                <br />
                <span>{acad.designation}</span>
              </div>
              <div className="profile-divider">
                <div className="social-link">
                  {acad.facebookurl ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={acad.facebookurl}
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  ) : (
                    ""
                  )}
                  {acad.instagramurl ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${acad.instagramurl}`}
                    >
                      <i className="fa fa-instagram mx-3"></i>
                    </a>
                  ) : (
                    ""
                  )}
                  {acad.twitterurl ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={acad.twitterurl}
                    >
                      <i className="fa fa-twitter"></i>
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }

    if (ecacommittee) {
      eca = ecacommittee.map((eca, idx) => {
        return (
          <div className="col-md-3" key={idx}>
            <div className="card">
              <div className="proImage">
                <img
                  alt=""
                  src={`${ecacommitteeimg.main_image_url}/${eca.image}`}
                  width="100%"
                />
              </div>
              <div className="profile-info text-center">
                <h6>{eca.name}</h6>
                <br />
                <span>{eca.designation}</span>
              </div>
              <div className="profile-divider">
                <div className="social-link">
                  {eca.facebookurl ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={eca.facebookurl}
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  ) : (
                    ""
                  )}
                  {eca.instagramurl ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${eca.instagramurl}`}
                    >
                      <i className="fa fa-instagram mx-3"></i>
                    </a>
                  ) : (
                    ""
                  )}
                  {eca.twitterurl ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={eca.twitterurl}
                    >
                      <i className="fa fa-twitter"></i>
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }

    if (examcommittee) {
      exam = examcommittee.map((excom, idx) => {
        return (
          <div className="col-md-3" key={idx}>
            <div className="card">
              <div className="proImage">
                <img
                  alt=""
                  src={`${examcommitteeimg.main_image_url}/${excom.image}`}
                  width="100%"
                />
              </div>
              <div className="profile-info text-center">
                <h6>{excom.name}</h6>
                <br />
                <span>{excom.designation}</span>
              </div>
              <div className="profile-divider">
                <div className="social-link">
                  {excom.facebookurl ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={excom.facebookurl}
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  ) : (
                    ""
                  )}
                  {excom.instagramurl ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${excom.instagramurl}`}
                    >
                      <i className="fa fa-instagram mx-3"></i>
                    </a>
                  ) : (
                    ""
                  )}
                  {excom.twitterurl ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={excom.twitterurl}
                    >
                      <i className="fa fa-twitter"></i>
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }

    if (counsel) {
      counselling = counsel.map((counselData, idx) => {
        return (
          <div className="col-md-3" key={idx}>
            <div className="card">
              <div className="proImage">
                <img
                  alt=""
                  src={`${counselimg.main_image_url}/${counselData.image}`}
                  width="100%"
                />
              </div>
              <div className="profile-info text-center">
                <h6>{counselData.name}</h6>
                <br />
                <span>{counselData.designation}</span>
              </div>
              <div className="profile-divider">
                <div className="social-link">
                  {counselData.facebookurl ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={counselData.facebookurl}
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  ) : (
                    ""
                  )}
                  {counselData.instagramurl ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${counselData.instagramurl}`}
                    >
                      <i className="fa fa-instagram mx-3"></i>
                    </a>
                  ) : (
                    ""
                  )}
                  {counselData.twitterurl ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={counselData.twitterurl}
                    >
                      <i className="fa fa-twitter"></i>
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  }

  return (
    <div>
      <section id="committee" className="acadMembers my-5">
        <h2 className="text-center">Our Committees</h2>
        <div className="container">
          <ul className="nav">
            <li className="nav-item">
              <Link
                className={`nav-link ${(navData) =>
                  navData.isActive ? "active" : ""}`}
                aria-current="page"
                data-bs-toggle="tab"
                to="#bod"
              >
                BOD
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${(navData) =>
                  navData.isActive ? "active" : ""}`}
                to="#schoolMgmt"
                data-bs-toggle="tab"
              >
                School Management
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${(navData) =>
                  navData.isActive ? "active" : ""}`}
                to="#academic"
                id="#academic"
                data-bs-toggle="tab"
              >
                Academic
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${(navData) =>
                  navData.isActive ? "active" : ""}`}
                to="#eca"
                data-bs-toggle="tab"
              >
                ECA
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${(navData) =>
                  navData.isActive ? "active" : ""}`}
                to="#examination"
                data-bs-toggle="tab"
              >
                Examination
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${(navData) =>
                  navData.isActive ? "active" : ""}`}
                to="#counselling"
                data-bs-toggle="tab"
              >
                Counselling
              </Link>
            </li>
          </ul>
          <br />
          <div className="row">
            <div className="tab-content">
              <div className="tab-pane fade show active" id="bod">
                <div className="row">{bodCommittee}</div>
              </div>
              <div className="tab-pane fade" id="schoolMgmt">
                <div className="row">{schoolMgmt}</div>
              </div>
              <div className="tab-pane fade" id="academic">
                <div className="row">{acadMgmt}</div>
              </div>
              <div className="tab-pane fade" id="eca">
                <div className="row">{eca}</div>
              </div>
              <div className="tab-pane fade" id="examination">
                <div className="row">{exam}</div>
              </div>
              <div className="tab-pane fade" id="counselling">
                <div className="row">{counselling}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AcadMembers;
