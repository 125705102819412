import axios from "axios";
import React, { useEffect, useState } from "react";

const MsgFrom = () => {
  const [loading, setLoading] = useState(true);
  const [msgs, setMsgs] = useState([]);
  const [image, setImage] = useState({});

  useEffect(() => {
    axios.get("https://admin.riaschool.edu.np/api/v1/profiles").then((res) => {
      if (res.status === 200) {
        setMsgs(res.data.data.emprofile);
        setImage(res.data.data.attributes);
      }
      setLoading(false);
    });
  }, []);

  let message;
  if (loading) {
    <div className="text-center align-item-center my-3">
      <div
        className="spinner-border text-success"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>;
  } else {
    message = msgs.map((msg, idx) => {
      if (msg.designation === "Chairperson") {
        return (
          <div className="col-md-12 col-lg-6" key={msg.id}>
            <div className="testimonial">
              <div className="row">
                <span
                  className="description"
                  dangerouslySetInnerHTML={{ __html: msg.message }}
                ></span>

                <div className="col-md-8">
                  <h3 className="title">{msg.name}</h3>
                  <br />
                  <small className="post">- {msg.designation}</small>
                </div>
                <div className="col-md-4">
                  <div className="pic">
                    <img src={`${image.main_image_url}/${msg.image}`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
      if (msg.designation === "Principal") {
        return (
          <div className="col-md-12 col-lg-6" key={msg.id}>
            <div className="testimonial">
              <div className="row">
                <span
                  className="description"
                  dangerouslySetInnerHTML={{ __html: `${msg.message}` }}
                ></span>
                <div className="col-md-8">
                  <h3 className="title">{msg.name}</h3>
                  <br />
                  <small className="post">- {msg.designation}</small>
                </div>
                <div className="col-md-4">
                  <div className="pic">
                    <img src={`${image.main_image_url}/${msg.image}`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    });
  }
  return (
    <>
      {/* <!-- Testimonials Sections --> */}
      <section className="testimonials mt-3">
        <div className="container">
          <div className="row">{message}</div>
        </div>
      </section>
    </>
  );
};

export default MsgFrom;
