import axios from "axios";
import React, { useEffect, useState } from "react";

const Facilities = () => {
  const [loading, setLoading] = useState(true);
  const [facilities, setFacilities] = useState([]);
  const [facilitiesImg, setFacilitiesImg] = useState({});

  useEffect(() => {
    axios
      .get("https://admin.riaschool.edu.np/api/v1/facilities")
      .then((res) => {
        if (res.status === 200) {
          setFacilities(res.data.data.facilities);
          setFacilitiesImg(res.data.data.attributes);
        }
        setLoading(false);
      });
  }, []);

  let facility;

  if (loading) {
    return (
      <div className="text-center align-item-center my-3">
        <div
          className="spinner-border text-success"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    facility = facilities.map((facilityData, idx) => {
      return facilityData.title !== "Facilities & ECA" ? (
        <span className="my-3" key={idx}>
          <h1>{facilityData.title}</h1>
          <div className="cca-objectives">
            <div className="image">
              <img
                src={`${facilitiesImg.main_image_url}/${facilityData.image}`}
                alt={facilityData.title}
              />
            </div>
            <span
              dangerouslySetInnerHTML={{
                __html: `${facilityData.description}`,
              }}
            />
          </div>
        </span>
      ) : (
        ""
      );
    });
  }
  return (
    <div>
      <section id="cca-eca">
        <div className="container">
          <div className="row">{facility}</div>
        </div>
      </section>
    </div>
  );
};

export default Facilities;
