import axios from "axios";
import React, { useEffect, useState } from "react";

const CCA = () => {
  const [loading, setLoading] = useState(true);
  const [ecas, setEcas] = useState([]);
  const [ecaImg, setEcaimg] = useState({});

  useEffect(() => {
    axios.get("https://admin.riaschool.edu.np/api/v1/cca").then((res) => {
      if (res.status === 200) {
        setEcas(res.data.data.ccas);
        setEcaimg(res.data.data.attributes);
      }
      setLoading(false);
    });
  }, []);

  let ecaAbout;
  let ecaActivities;
  if (loading) {
    return (
      <div className="text-center align-item-center my-3">
        <div
          className="spinner-border text-success"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    ecaAbout = ecas.map(
      (eca, idx) => {
        return eca.title === "CCA & ECA" ? (
          <div key={idx}>
            <h1>{eca.title}</h1>
            <div className="eca-about">
              <div className="container">
                <span
                  dangerouslySetInnerHTML={{ __html: `${eca.description}` }}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        );
      },
      (ecaActivities = ecas.map((club, idx) => {
        return club.title !== "CCA & ECA" ? (
          <span key={idx}>
            <h1>{club.title}</h1>
            <div className="cca-objectives">
              <div className="image">
                <img
                  src={`${ecaImg.main_image_url}/${club.image}`}
                  alt={club.title}
                />
              </div>
              <span
                dangerouslySetInnerHTML={{ __html: `${club.description}` }}
              />
            </div>
          </span>
        ) : (
          ""
        );
      }))
    );
  }
  return (
    <div>
      <section id="cca-eca">
        <div className="container">
          <div className="row">{ecaAbout}</div>
          <div className="row">{ecaActivities}</div>
        </div>
      </section>
    </div>
  );
};

export default CCA;
