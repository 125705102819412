import axios from "axios";
import React, { useState, useEffect } from "react";
import NoticeCard from "./NoticeCard";

const Notice = () => {
  const [loading, setLoading] = useState(true);
  const [setting, setSetting] = useState({});

  useEffect(() => {
    axios.get("https://admin.riaschool.edu.np/api/v1/setting").then((res) => {
      if (res.status === 200) {
        setSetting(res.data.data.setting);
      }
      setLoading(false);
    });
  }, []);

  let youtube;
  if (
    !setting ||
    setting.youtubeembed === null ||
    setting.youtubeembed === undefined
  ) {
    return (
      <div className="text-center align-item-center my-3">
        <div
          className="spinner-border text-success"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    youtube = (
      <iframe
        src={setting.youtubeembed}
        title="YouTube video"
        allowFullScreen
      ></iframe>
    );
  }
  return (
    // <!-- Notice Board Sections -->
    <section className="noticeboard my-4">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <span
              style={{
                fontSize: "30px",
                fontWeight: "700",
                color: "#00adb5",
              }}
            >
              Notice Board
            </span>
            <div className="notice">
              <NoticeCard />
            </div>
          </div>
          <div className="col-md-6">
            <span
              style={{
                fontSize: "30px",
                fontWeight: "700",
                color: "#00adb5",
              }}
            >
              Online Media
            </span>
            <div className="ratio ratio-4x3">{youtube}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Notice;
