import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./component/content/Content";
import Footer from "./component/layout/Footer";
import Header from "./component/layout/Header";
import About from "./component/pages/about/About";
import Academic from "./component/pages/academic/Academic";
import Awards from "./component/pages/awards/Awards";
import CCA from "./component/pages/cca/CCA";
import Contact from "./component/pages/contact/Contact";
import Facilities from "./component/pages/facilities/Facilities";
import Gallery from "./component/pages/gallery/Gallery";

function App() {
  return (
    <div>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/academic" element={<Academic />} />
          <Route path="/cca" element={<CCA />} />
          <Route path="/facilities" element={<Facilities />} />
          <Route path="/awards" element={<Awards />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
